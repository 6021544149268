import React from "react";
import Button from "../../components/Button/Button";
import Content from "../../components/Content/Content";
import '../../index.scss';

export default function ContactPage(){
    document.title = "Contact page";
    return (
        <div className="mainFrame">
            <Content
                text="Contact me if you have any questions."/>
            <div className="sendEmail">
                <a href="mailto:bjorn.r.pettersson@gmail.com">
                    <Button
                        styleType="email"
                        text="Send me an e-mail"
                    />
                </a>
            </div>
        </div>
    )
}