import React from "react";
import bachelor from '../../bachelor.jpg';
import Content from "../../components/Content/Content";
import '../../index.scss';
import kode from "../../kode.jpg";
import portfolio from '../../portfoliopage.jpg';

export default function PortfolioPage(){
    document.title = "Portfolio page";
    return (
        <div className="mainFrame">
            <Content
                text="Here is an assortment of my works."
            />
            <div className="cardsPortfolio">
                <div className="card">
                    <img src={bachelor} alt="Avatar"/>
                    <div className="container">
                        <h4><b>Bachelor project</b></h4>
                        <p>New solution of handling shortages of medicinal products for the Norwegian Medicines Agency in collaboration with Netcompany</p>
                    </div>
                </div>
                <div className="card">
                    <img src={kode} alt="Avatar"/>
                    <div className="container">
                        <h4><b>Event app</b></h4>
                        <p>Coming soon ...</p>
                    </div>
                </div>
                <div className="card">
                    <img src={portfolio} alt="Avatar"/>
                    <div class="container">
                        <h4><b>Portfolio website</b></h4>
                        <p>Designing, developing and publishing this website using React, SCSS and Azure focusing on a responsive and user friendly interface</p>
                    </div>
                </div>
            </div>
        </div>
    )
}