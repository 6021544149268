import React from "react";
import github from "../../github.svg";
import '../../index.scss';
import instagram from "../../instagram-logo.svg";
import linkedIn from "../../linkedin.svg";

export default function Footer(){
    return (
        <div className="footerFrame">
            <div className="titleFooter">
                <p>Check out my profile on these sites</p>
            </div>
            <div className="linksFooter">
                <div className="linkedIn">
                    <a href="https://www.linkedin.com/in/bj%C3%B6rnpettersson/" target="_blank" rel="noreferrer">
                        <img id='icon' alt='Ikon for LinkedIn' src={linkedIn} />
                    </a>
                </div>
                <div className="instagram">
                    <a href="https://www.instagram.com/beornus/" target="_blank" rel="noreferrer">
                        <img id='icon' alt='Ikon for Instagram' src={instagram} />
                    </a>
                </div>
                <div className="github">
                    <a href="https://github.com/Beornus" target="_blank" rel="noreferrer">
                        <img id='icon' alt='Ikon for Github' src={github} />
                    </a>
                </div>
            </div>
        </div>
    )
}