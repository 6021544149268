import React from 'react';
import {
  BrowserRouter as Router, Route, Routes
} from 'react-router-dom';
import AboutPage from './pages/AboutPage/AboutPage';
import ContactPage from './pages/ContactPage/ContactPage';
import FrontPage from './pages/FrontPage/FrontPage';
import Layout from './pages/Layout/Layout';
import PortfolioPage from './pages/PortfolioPage/PortfolioPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Layout />} >
          <Route index element={<FrontPage />} />
          <Route path='/contact'>
            <Route index element={<ContactPage />} />
          </Route>
          <Route path='/portfolio'>
            <Route index element={<PortfolioPage />} />
          </Route>
          <Route path='/about'>
            <Route index element={<AboutPage />} />
          </Route>  
        </Route>
      </Routes>
    </Router>
  )
}

export default App;
