import React from "react";
import Button from "../../components/Button/Button";
import '../../index.scss';

export default function NavigationRow(){
    return (
        <div className="navigationRow">
            <div className="navigationElement">
                <Button
                    navigationLink="/portfolio"
                    styleType="navigate"
                    text="Portfolio"
                />
            </div>
            <div className="navigationElement">
                <Button
                    navigationLink="/about"
                    styleType="navigate"
                    text="Who Am I"
                />
            </div>
            <div className="navigationElement">
                <Button
                    navigationLink="/contact"
                    styleType="navigate"
                    text="Contact"
                />
            </div>
        </div>
    )
}