import React from "react";
import { useNavigate } from "react-router-dom";
import '../../index.scss';

export default function Button(props){

    const navigateHere = useNavigate();

    let navigateTo = () => {
        if (props.navigationLink) {
            navigateHere(props.navigationLink);
        }
    }

    let GetStyle = () => {
        if(props.styleType === "navigate"){
            return "button--navigate"
        }

        if(props.styleType === "title"){
            return "button--title"
        }

        if(props.styleType === "email"){
            return "button--email"
        }
    }

    return (
        <div className="buttonFrame">
            <button className={GetStyle(props.styleType)} onClick={(props.navigation) ? props.navigation : navigateTo}>
                {props.text}
            </button>
        </div>
    )
}