import React from "react";
import Content from "../../components/Content/Content";
import '../../index.scss';
import profile from "../../profil.jpg";

export default function AboutPage(){
    document.title = "About page";

    return (
        <div className="mainFrame">
            <div className="profilePicture">
                <img src={profile} alt="Profile of Björn"/>
                <div className="overProfile">
                    <p>
                    <a href="https://www.instagram.com/beornus/">See more pictures ></a>
                    </p>
                </div>
            </div>
            <Content
                text="Björn is a full stack developer with a bachelor's degree in computer science"
            />
        </div>
    )
}