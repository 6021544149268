import React, { useEffect, useState } from "react";
import Content from "../../components/Content/Content";
import { urlWeather } from "../../endpoints";
import '../../index.scss';

export default function FrontPage(){

    const useFetch = url => {
        const [data, setData] = useState(null);

        async function fetchData(){
            const response = await fetch(url);
            const json = await response.json();
            setData(json);
        }

        useEffect(() => {fetchData()}, [url]);

        return data;
    }

    const data = useFetch(urlWeather);


    document.title = "Front page";
    return (
        <div className="mainFrame">
            <Content
                text="Designing and creating functional and beautiful things is my passion."
            />
            <div className='subText'>
                <p>My main keyword is inclusivity. I don't care who you are. Where you're from. What you did. As long as you can use what I make</p>
            </div>
            {console.log(data)}
        </div>
    )
}