import React from "react";
import '../../index.scss';
import Button from "../Button/Button";
import NavigationRow from "../NavigationRow/NavigationRow";

export default function Header(){

    return (
        <div className="headerFrame">
            <div className="titleHeader">
            <Button
                    navigationLink="/"
                    styleType="title"
                    text="Björn Pettersson"
                />
            </div>
            <div className="navigationHeader">
                <NavigationRow />
            </div>
        </div>
    )
}